import { Link } from 'react-router-dom';

import logo from '../img/logo.png';

export default function Navbar() {

  return (

    <nav>
      <div className="nav-wrapper">
        <div className="nav-left">
        </div>
        <Link to="/" className="logo-link">
          <img src={logo} alt="Demos" />
        </Link>
        <div className="nav-right">
        </div>
      </div>
    </nav>
  );
}