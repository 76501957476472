import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './css/style.css';

import LandingPage from "./pages/LandingPage";


function App() {

  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route index element={<LandingPage />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </BrowserRouter>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
