
export default function Footer() {

  return (

    <footer>
      <span>&copy; Copyright 2024 Demos Global</span>
    </footer>

  );
}