
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import ellipse from '../img/ellipse.svg';

export default function LandingPage() {

  return (
    <>
      <Navbar />

      <div className="background-elements">
        <img src={ellipse} alt="" className="ellipse-1" />
        <img src={ellipse} alt="" className="ellipse-2" />
      </div>

      <div className="content-wrapper">
        {

          <>
            {
              <>
                <h1 className="header-big">
                  We are under maintenance
                </h1>
                <h2>Next launch details will be announced soon</h2>
                Check <a href="https://twitter.com/demos_global" target="_blank" rel="noreferrer" style={{color: "white"}}>Twitter</a> for updates
              </>
            }
            <br />
          </>
        }
      </div >

      <Footer />
    </>
  );
}